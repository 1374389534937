/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";

// reactstrap components
import {
  Button,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import ReCAPTCHA from "react-google-recaptcha";

// core components
import ContactNavbar from "components/Navbars/ContactNavbar.js";
import Footer from "components/Footers/Footer.js";

function ContactPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  const [formData, setFormData] = useState({});
  const [messageStatus, setMessageStatus] = useState({messageSent: false, messageError: false});

  const recaptchaRef = React.createRef();

  const updateInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    recaptchaRef.current.execute();
  }

  const onChange = () => {
    setMessageStatus({ messageSent: true });
    sendEmail();
    setFormData({
      name: "",
      email: "",
      message: "",
    });
  };
  const sendEmail = () => {
  };

  return (
    <>
      <ContactNavbar />
      <div className="main">
        <div className="section landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">
                  ¿Te interesa conocer más sobre nuestro servicio?
                </h2>
                <Form className="contact-form" onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6">
                      <label>Nombre</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          placeholder="Nombre"
                          type="text"
                          name="name"
                          onChange={updateInput}
                          value={formData.name || ""}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          required
                          type="email"
                          name="email"
                          placeholder="Email"
                          onChange={updateInput}
                          value={formData.email || ""}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <label>Mensaje</label>
                  <Input
                    required
                    type="textarea"
                    name="message"
                    placeholder="Mandanos tu mensaje o consulta!"
                    onChange={updateInput}
                    value={formData.message || ""}
                    rows="8"
                  />
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LfGhwAVAAAAAJMohxu3hNEurpLFsvYJz0y2M5Q1"
                        size="invisible"
                        onChange={onChange}
                      />
                      <Button className="btn-fill" color={ messageStatus.messageSent ? ( messageStatus.messageError ? "danger" : "success") : "info"} size="lg">
                        {messageStatus.messageSent ? ( messageStatus.messageError ?  "Hubo un error" : "Mensaje enviado!" ) : "Enviar Mensaje"}
                      </Button>
                    </Col>
                  </Row> 
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactPage;
