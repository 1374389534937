/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components

const tour1 = {
  title: "Casa Modelo",
  src: "https://my.matterport.com/show/?m=DDEPeT5iLgX",
  width: "100%",
  height: "500",
  frameBorder: "0",
  altText: "Tour 1"
}

const tour2 = {
  title: "Gimnasio",
  src: "https://my.matterport.com/show/?m=xpDTsLaUXtm",
  width: "100%",
  height: "500",
  frameBorder: "0",
  altText: "Tour 2"
}

const tour3 = {
  title: "Casa de tres plantas",
  src: "https://my.matterport.com/show/?m=KqQnLME3WzB",
  width: "100%",
  height: "500",
  frameBorder: "0",
  altText: "Tour 3"
}

// const items = [
//   {
//     title: "Tour 1",
//     src: "https://my.matterport.com/show/?m=DDEPeT5iLgX",
//     width: "100%",
//     height: "400",
//     frameBorder: "0",
//     altText: "Tour 1"
//   },
//   {
//     title: "Tour 2",
//     src: "https://my.matterport.com/show/?m=KqQnLME3WzB",
//     width: "100%",
//     height: "400",
//     frameBorder: "0",
//     altText: "Tour 2"
//   },
//   {
//     title: "Tour 3",
//     src: "https://my.matterport.com/show/?m=ACVTMtacSxJ",
//     width: "100%",
//     height: "400",
//     frameBorder: "0",
//     altText: "Tour 3"
//   },
//   {
//     title: "Tour 4",
//     src: "https://my.matterport.com/show/?m=qXD7oV8gZB4",
//     width: "100%",
//     height: "400",
//     frameBorder: "0",
//     altText: "Tour 4"
//   }

function SectionCarousel() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  // const onExiting = () => {
  //   setAnimating(true);
  // };
  // const onExited = () => {
  //   setAnimating(false);
  // };
  // const next = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
  //   setActiveIndex(nextIndex);
  // };
  // const previous = () => {
  //   if (animating) return;
  //   const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
  //   setActiveIndex(nextIndex);
  // };
  // const goToIndex = newIndex => {
  //   if (animating) return;
  //   setActiveIndex(newIndex);
  // };
  return (
    <>
      <div className="section pt-o" id="carousel">
        <Container>
          <Row style={{ marginBottom: 90, textAlign: 'center' }}>
            <h2 style={{ fontWeight: "400", fontSize: 40 }}>Ejemplos de toures virtuales 360</h2>
          </Row>
          <Row>
            <Col lg="4" md="12" style={{ alignSelf: "center" }}>
              <h2 className="title" style={{ fontSize: 35 }}>{tour1.title}</h2>
            </Col>
            <Col lg="8" md="12">
              <iframe title={tour1.title} src={tour1.src} width={tour1.width} height={tour1.height} frameBorder={tour1.frameBorder} alt={tour1.altText} allowFullScreen="allowfullscreen" />
            </Col>
          </Row>
          <Row style={{ marginTop: 100 }}>
            <Col lg="4" md="12" style={{ alignSelf: "center" }}>
            <h2 className="title" style={{ fontSize: 35 }}>{tour2.title}</h2>
            </Col>
            <Col lg="8" md="12">
              <iframe title={tour2.title} src={tour2.src} width={tour2.width} height={tour2.height} frameBorder={tour2.frameBorder} alt={tour2.altText} allowFullScreen="allowfullscreen" />
            </Col>
          </Row>
          <Row style={{ marginTop: 100 }}>
            <Col lg="4" md="12" style={{ alignSelf: "center" }}>
            <h2 className="title" style={{ fontSize: 35 }}>{tour3.title}</h2>
            </Col>
            <Col lg="8" md="12">
              <iframe title={tour3.title} src={tour3.src} width={tour3.width} height={tour3.height} frameBorder={tour3.frameBorder} alt={tour3.altText} allowFullScreen="allowfullscreen" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionCarousel;
