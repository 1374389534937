/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
/*
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{ 
          position: "relative",
          minHeight: "300px",
          maxHeight: "800px",
          overflow: "hidden",
          marginBottom: "500px"
        }}
      >
        <video autoPlay="autoplay" loop="loop" muted style={{width: "100%", height: "100%"}} >
                <source src={require("assets/video/BeThereClip.mov")} type="video/mp4" />
                Your browser does not support the video tag.
        </video>
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div style={{ 
          position: "absolute",
          top: 0,
          color:"#f1f1f1",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0
        }}>
              <div className="title-brand">
                <h1 className="presentation-title">Be There.</h1>
                <div className="fog-low">
                  <img alt="..." src={require("assets/img/fog-low.png")} />
                </div>
                <div className="fog-low right">
                  <img alt="..." src={require("assets/img/fog-low.png")} />
                </div>
              </div>
              <h2 className="presentation-subtitle text-center">
                Some catchy frase here.
              </h2>
            </div>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")"
          }}
        />
      </div>
    </>
  );
}

export default IndexHeader;

*/

import React from "react";

// reactstrap components
import "./IndexHeaderStyles.scss";

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{ 
          position: "relative",
          minHeight: "300px",
          maxHeight: "800px",
          overflow: "hidden"
        }}
      >
        <video autoPlay="autoplay" loop="loop" muted style={{width: "100%", height: "100%"}} >
                <source src={require("assets/video/BT-Portada.MP4")} type="video/mp4" />
                Your browser does not support the video tag.
        </video>
      
        <div style={{ 
          position: "absolute",
          top: 0,
          color:"#f1f1f1",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 0
        }}>
              <div className="title-brand">
                <h1 className="presentation-title">Be There.</h1>
              </div>
          </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")"
          }}
        />
      </div>
    </>
  );
}

export default IndexHeader;
