/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

function MainServices() {
  return (
    <>
      <div className="section section-buttons">
        <Container>
          <div className="title" style={{ textAlign: 'center', marginBottom: 50 }}>
            <h1 style={{ fontSize: 60 }}>Creamos experiencias interactivas,</h1> 
            <h1 style={{ fontSize: 60 }}>como si estuvieras ahí.</h1>
            <hr style={{ height: 1, color: 'gray', backgroundColor: 'gray', borderRadius: 12, width: '60%', marginTop: 50 }} />
          </div>
          <Row>
            <Col lg="6" sm="12">
              <div className="title">
                <h2 style={{ fontWeight: "bolder" }}>Tour Virtual 360</h2>
              </div>
              <h4>
                Tour virtual interactivo para mostrar la totalidad del espacio,
                dando la posibilidad a tus clientes de conocer tu propiedad o
                negocio de una manera diferente.
              </h4>
            </Col>
            <Col lg="6" sm="12">
              <div className="title">
                <h2 style={{ fontWeight: "bolder" }}>Galería Multimedia</h2>
              </div>
              <h4>
                Además del Tour Virtual, te entregamos fotos y videos para que
                puedas promocionar tu propiedad en tus plataformas digitales.
              </h4>
            </Col>
          </Row>
          <Row>
            <Col lg="6" sm="12">
              <div className="title">
                <h2 style={{ fontWeight: "bolder" }}>Etiquetas Informativas</h2>
              </div>
              <h4>
                Agregá información relevante sobre aspectos que se quieran destacar dentro del tour. Materialidad, tecnología instalada, promociones de productos, catálogos, etc.
              </h4>
            </Col>
            <Col lg="6" sm="12">
              <div className="title">
                <h2 style={{ fontWeight: "bolder" }}>
                  Experiencia VR inmersiva
                </h2>
              </div>
              <h4>
                Mostrá el interior de tu propiedad de una manera innovadora
                utilizando visores de realidad virtual.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default MainServices;
