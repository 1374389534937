/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionVistas() {
  return (
    <>
      <div className="section section-dark section-nucleo-icons">
        <Container>
          <Row>
            <Col lg="4" md="12" style={{ alignSelf: "center" }}>
              <h2 className="title">Vista DollHouse</h2>
              <br />
              <h4 className="description">
                La vista DollHouse permite visualizar cómo se conectan los distintos espacios y navegar hacia cualquier lugar de la propiedad. 
              </h4>
            </Col>
            <Col lg="8" md="12">
            <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/DollHouseCM.png")}
                  style={{ width: "100%" }}
                />
            </Col>
          </Row>
          <br />
          <br />
          <br />
          <Row>
            <Col lg="8" md="12">
            <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/PlantaCM.png")}
                  style={{ width: "100%" }}
                />
            </Col>
            <Col lg="4" md="12" style={{ alignSelf: "center" }}>
              <h2 className="title">Vista Planta 2D</h2>
              <br />
              <h4 className="description">
                Permite comprender la distribución de los espacios, individualizando cada uno de los niveles de la propiedad y tomar medidas sobre ellos.
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionVistas;
